<template>
  <div style="min-height: calc(100vh - 158px);" class="card">
    <div style="background: #DBE7F2" class="card p-2">
      <TitleButton
          :showBtn="false"
          :showAddNew="false"
          title="Stock Register"
      />

      <div class="row mt-2">
        <div class="col-md-4">
          <label for="colFormLabel">
              Product/Service Type
          </label>
          <v-select
              placeholder="Select Product/Service Type"
              v-model="product_type"
              :options="product_types"
              label="text"
              :reduce="text => text.id"
          />
        </div>
        <div class="col-md-4">
          <label for="colFormLabel">
              Product/Service
          </label>
          <v-select
              placeholder="Select Product/Service"
              v-model="product"
              :options="products"
              label="text"
              :reduce="text => text.id"
          />
        </div>
        <div class="col-md-4">
          <label for="colFormLabel">
              Inventory Location
          </label>
          <v-select
              placeholder="Select Inventory Location"
              v-model="location"
              :options="locations"
              label="text"
              :reduce="text => text.id"
          />
        </div>
      </div>
      <div class="col-12 col-lg-8 col-xl-9 col-xxl-7 mt-2">
        <DateQuerySetter
            btnTitle="Go"
            @onClickGo="onClickGo"
        />
      </div>
    </div>

    <div class="col-12">
      <div class="d-flex justify-content-end px-2">
        <button
            @click="exportTable"
            class="btn btn-outline-secondary btn-sm btn-download-invoice waves-effect"
        >
          Export
        </button>
      </div>
    </div>

    <div class="card mt-1 px-2">
      <StockRegisterTable :balances="stockRegisterData"/>
    </div>
    <Loader v-if="reportLoading"/>
  </div>
</template>

<script>
import handleReport from '@/services/modules/inventory'
import { inject } from 'vue'

import TitleButton from '@/components/atom/TitleButton'
import DateQuerySetter from '@/components/atom/DateQuerySetter'
import StockRegisterTable from '@/components/molecule/company/stock-register/StockRegisterTable'
import Loader from '@/components/atom/LoaderComponent'
import handleBusinessesLocations from '@/services/modules/businessesLocations'
import handlePurchase from '@/services/modules/purchase'
const {fetchBusinessLocationsList} = handleBusinessesLocations()
const {fetchProductList} = handlePurchase()


export default {
  name: 'StockRegister',

  components: {
    TitleButton,
    Loader,
    DateQuerySetter,
    StockRegisterTable
  },

  data: () => ({
    product_type: null,
    product: null,
    location: null,
    stockRegisterData: [],
    locations: [],
    products: [],
    product_types: [],
    homeContent: {},
  }),

  computed: {
    companyId() {
      return this.$route.params.companyId
    },
    start() {
      return this.$route.query.start
    },
    end() {
      return this.$route.query.end
    },
    token () {
      return localStorage.getItem('token')
    }
  },

  methods: {
    onClickGo() {
      this.getStockRegisterBalance(this.getQuery())
    },

    exportTable () {
      let url = `${process.env.VUE_APP_BASE_URL}/export/inventory/stock-register?company_id=${this.companyId}&start_date=${this.start}&end_date=${this.end}&product_type=${this.product_type}&product=${this.product}&location=${this.location}&_token=${this.token}`
      let a = document.createElement("a")
      a.setAttribute("href", url)
      a.click()
    },

    getQuery () {
      let query = '?company_id=' + this.companyId
      if(!this.start && !this.end) return query
      if(this.start) query += '&start_date='+this.start
      if(this.end) query += '&end_date='+this.end
      query += '&product_type='+this.product_type
      query += '&product='+this.product
      query += '&location='+this.location
      return query
    },

    async getStockRegisterBalance(query = '') {
      try {
        this.reportLoading = true
        let res = await this.getStockRegisterReport(query)
        if(!res.status) {
          this.stockRegisterData = [];
          this.showError(res.message)
        }
        if(res.status) {
          this.stockRegisterData = res.data
        }
      } catch (err) {
        this.stockRegisterData = [];
        if(!err.response) {
          this.showError('Something is wrong. Check your connectivity!!')
        }
        if(err.response?.data) {
          this.showError(err.response.data.message)
          return
        }
      } finally {
        this.reportLoading = false
      }
    },

    async getHomeContent(type) {
      try {
        this.reportLoading = true
        let res = await this.fetchHome()
        if(!res.status) {
          this.showError(res.message)
        }
        if(res.status) {
          this.homeContent = res.data;
          this.makeProductTypesDropdownOptions();
        }
      } catch (err) {
        if(!err.response) {
          this.showError('Something is wrong. Check your connectivity!!')
        }
        if(err.response) {
          this.showError(err.response.message)
        }
      } finally {
        this.setHeaderDropdownOptions();
        this.reportLoading = false;
      }
    },

    makeProductTypesDropdownOptions() {
      for (var key in this.homeContent.product_type) {
        if (this.homeContent.product_type.hasOwnProperty(key)) {
          let obj = {
            id : key,
            text : this.homeContent.product_type[key]
          }
          this.product_types.push(obj);
        }
      }

      this.product_types.push({
        id : 'all',
        text : 'All'
      });
    },

    setHeaderDropdownOptions() {
      this.product_type = 'all';
      this.product      = 'all';
      this.location     = 'all';
    }
  },

  setup() {
    const showError =  inject('showError');
    const showSuccess =  inject('showSuccess');
    const {
            fetchHome,
            getStockRegisterReport,
            reportLoading,
          } = handleReport()


    return {
      getStockRegisterReport,
      reportLoading,
      showError,
      showSuccess,
      fetchHome,
    }
  },

  mounted () {
    this.getStockRegisterBalance(this.getQuery())
    const companyQuery = `?company_id=${this.companyId}`
    const businessLocationRes = fetchBusinessLocationsList(companyQuery)
    const productRes = fetchProductList(companyQuery)
    Promise.all([
      businessLocationRes.then(res=> {
        if(res.data){
          this.locations = res.data;
          this.locations.push({
            id : 'all',
            text : 'All'
          });
        }
      }),
      productRes.then(res=> {
        if(res.data){
          this.products = res.data;
          this.products.push({
            id : 'all',
            text : 'All'
          });
        }
      }),
    ])
    .then(() => {this.reportLoading = false})
    .catch((err)=>{this.reportLoading = false})

    this.getHomeContent();
  }
}
</script>
